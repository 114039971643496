import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import styled from "styled-components"

const HeaderSection = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: auto;
  flex-flow: row;
  background: black;
`

const HeaderText = styled.div`
  border: 0px solid green;
  font-family: montserrat;
  width: 100%;
`
const List = styled.ul`
  margin: 0;
  padding: 1rem;
  color: white;
  background-color: #173b0b;
`

const BreadCrumb = styled.li`
  display: inline;
  padding: 1rem;
`
const BreadCrumbLink = styled(Link)`
  color: white;
  text-align: center;
  padding: 24px 16px;
  color: #ccc;
  text-decoration: none;
`

const IndexPage = () => (
  <Layout>
    <HeaderSection>
      <HeaderText>
        <List>
          <BreadCrumb>
            <span>
              <BreadCrumbLink to="/">Home </BreadCrumbLink>
            </span>
            <span>About</span>
          </BreadCrumb>
        </List>
      </HeaderText>
    </HeaderSection>

    <h1>About us</h1>
    <Link to="/page-2/">Go to page 2</Link>
  </Layout>
)

export default IndexPage
